const getDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;
  return `${year}-${month}-${day}`;
};
const FechaE = getDate();
export default {
  Id_Empleado: parseInt(window.sessionStorage.getItem("userId")) || 0,
  Fecha: "",
  Fecha2: "",
  Id_Excepcion: 0,
  Comentarios: "",
  FechaE,
};
