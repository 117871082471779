import React from "react";
import { Card, Form, Button } from "react-bootstrap";
import { request } from "../helpers";
class LoginPage extends React.Component {
  state = {
    messageError: "",
    credentials: {
      email: "",
      password: "",
    },
  };

  handleChange = ({ target }) => {
    const { credentials } = this.state;
    const name = target.name;
    const value = target.value;
    credentials[name] = value;
    this.setState({ credentials });
  };
  login = async (ev) => {
    try {
      ev.preventDefault();
      const { credentials } = this.state;
      const response = await request.post("/api/ch/login", credentials);
      if (response.error) {
        console.log(response);
        this.setState({
          messageError: response.message,
        });
      } else {
        console.log(response.data);
        window.sessionStorage.setItem("token", response.data.token);
        window.sessionStorage.setItem("userId", response.data.user.Id);
        window.sessionStorage.setItem("userName", response.data.user.Nombre);
        window.location.reload();
        // window.location.href ="/controlhoras/?view=ControlHoras&menu=ch"
      }
    } catch (error) {
      console.log(error);
      alert("Ocurrio un error, revisa la consola");
      this.setState({
        messageError: "",
      });
    }
  };
  render() {
    const { credentials, messageError } = this.state;
    const img =
      "https://www.seisaenergia.com/hs-fs/hubfs/Seisa_LogoBlack-01-1.png?width=1164&name=Seisa_LogoBlack-01-1.png";
    return (
      <div
        style={{
          background: "#e1e1e1",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card style={{ width: "18rem" }}>
          <Form onSubmit={this.login}>
            <Card.Body>
              <Card.Img src={img} />
              <Card.Title>Control de Horas</Card.Title>
              <Card.Subtitle>
                <span style={{ color: "red" }}> {messageError} </span>
              </Card.Subtitle>
              <Form.Group>
                <Form.Label>Correo</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="user@dominio.com"
                  onChange={this.handleChange}
                  value={credentials.email}
                  name="email"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={this.handleChange}
                  value={credentials.password}
                  name="password"
                  required
                />
              </Form.Group>
            </Card.Body>
            <Card.Footer>
              <Button type="submit">Login</Button>
            </Card.Footer>
          </Form>
        </Card>
      </div>
    );
  }
}

export default LoginPage;
