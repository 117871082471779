import React from "react";
import AppBar from "./components/AppBar";
import { Container } from "react-bootstrap";

//Funtions
function getParams(view) {
  const params = {
    view: view || "Home",
  };
  let urlParse = window.location.search.substr(1);
  urlParse = urlParse.split("&");
  for (let i in urlParse) {
    const kv = urlParse[i].split("=");
    params[kv[0]] = kv[1];
  }
  return params;
}

const AppRouter = (props) => {
  const params = getParams();
  let View = "Home";
  View = React.lazy(() =>
    import("./views/" + params.view).catch(() => {
      return import("./views/ViewFail");
    })
  );

  const menu = params.menu || "home";
  const menuOptions = props.menuOptions[menu];

  return (
    <React.Suspense fallback={<div>Cargando..</div>}>
      <div className="layout">
        <AppBar menuOptions={menuOptions} />
        <Container className="mt-1">
          <View params={params} />
        </Container>
      </div>
    </React.Suspense>
  );
};

export default AppRouter;
