const getDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;
  return `${year}-${month}-${day}`;
};
const FechaE = getDate();
const tHora = {
  Id_Empleado: parseInt(window.sessionStorage.getItem("userId")) || 0,
  Fecha: "0000-00-00",
  Tipo: 0,
  Id_Proyecto: 0,
  Id_Actividad: 0,
  Id_Excepcion: 0,
  Descripcion: "",
  Horas: 0.0,
  HorasExtra: 0.0,
  FechaE,
};

export default tHora;
