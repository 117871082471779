import React from "react";
import { Navbar, Image, Nav, NavDropdown } from "react-bootstrap";

class AppBar extends React.Component {
  userName = window.sessionStorage.getItem("userName");
  logOut() {
    window.sessionStorage.clear();
    window.location.href = "/app";
  }
  UserMenu = () => (
    <>
      <span className="color-white user-name">{this.userName}</span>
      <Image
        src="/avatar"
        roundedCircle
        style={{ width: 35, marginLeft: 10, background: "#ffffff" }}
      />
    </>
  );
  render() {
    return (
      <Navbar bg="dark" className="color-nav">
        <div className="navbar-menu">
          <div className="nav-start">
            <Navbar.Collapse>
              <NavDropdown
                title={<i className="material-icons color-white">menu</i>}
                id="main-menu"
                className="hide-dropdown-toggle"
              >
                <NavDropdown.Item href="/app/?view=Home&menu=home">
                  Inicio
                </NavDropdown.Item>
                <NavDropdown.Item href="/app/?view=ControlHoras&menu=ch">
                  Control de Horas
                </NavDropdown.Item>
                <NavDropdown.Item href="/app/?view=Tutoriales&menu=tutoriales">
                  Tutoriales
                </NavDropdown.Item>
              </NavDropdown>
            </Navbar.Collapse>
          </div>
          <div className="nav-center">
            {this.props.menuOptions.map((opt, i) => (
              <Nav.Link
                href={opt.href}
                key={opt.label + 1}
                style={{ color: "white" }}
                className="btn-style hide-caret"
              >
                {opt.label}
              </Nav.Link>
            ))}
          </div>

          <div className="nav-end">
            <Navbar.Collapse>
              <NavDropdown title={<this.UserMenu />} id="user-menu">
                <NavDropdown.Item onClick={this.logOut}>
                  Cerrar Sesión
                </NavDropdown.Item>
              </NavDropdown>
            </Navbar.Collapse>
          </div>
        </div>
      </Navbar>
    );
  }
}

export default AppBar;
