function loading() {
  const layout = document.createElement("div");
  layout.setAttribute("class", "loading-layout");
  layout.setAttribute("id", "loading-layout");

  ["#aeaeae", "#8d8d8d", "#707070", "#494949", "#373737"].forEach((color) => {
    const loadItem = document.createElement("div");
    loadItem.setAttribute("class", "loading-item");
    loadItem.style.background = color;
    layout.appendChild(loadItem);
  });
  document.body.appendChild(layout);
  return {
    show: () => layout.setAttribute("class", "loading-show"),
    close: () => layout.remove(),
  };
}
export default {
  config: {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-token": window.sessionStorage.getItem("token") || "",
    },
    body: "",
  },
  async get(url) {
    const load = loading();
    try {
      if ("body" in this.config) delete this.config.body;
      this.config.method = "get";
      const request = await fetch(url, this.config);
      const response = await request.json();
      load.close();
      return response;
    } catch (error) {
      load.close();
      return error;
    }
  },
  async post(url, body = {}) {
    const load = loading();
    try {
      this.config.body = JSON.stringify(body);
      this.config.method = "post";
      const request = await fetch(url, this.config);
      const response = await request.json();
      load.close();
      return response;
    } catch (error) {
      load.close();
      return error;
    }
  },
  async delete(url) {
    const load = loading();
    try {
      if ("body" in this.config) delete this.config.body;
      this.config.method = "delete";
      const request = await fetch(url, this.config);
      const response = await request.json();
      load.close();
      return response;
    } catch (error) {
      load.close();
      return error;
    }
  },
};
