import React from "react";

import Router from "./Router";
import LoginPage from "./views/LoginPage";

const options = {
  home: [],
  ch: [
    {
      label: "Control de Horas",
      href: "/app/?view=ControlHoras&menu=ch",
    },
    {
      label: "Excepciones",
      href: "/app/?view=AgregarExcepcion&menu=ch",
    },
  ],
  tutoriales: [],
};

class App extends React.Component {
  state = {
    isAuth: false,
  };

  componentDidMount() {
    const token = window.sessionStorage.getItem("token");
    if (token !== "" && token !== null) {
      this.setState({ isAuth: true });
    }
  }

  render() {
    const { isAuth } = this.state;
    return <>{isAuth ? <Router menuOptions={options} /> : <LoginPage />}</>;
  }
}

export default App;
